<template>
    <router-view></router-view>
    <h1>Master Vendor</h1>
    <hr />
    <bs-error-message :errors="errorMessages" />
    
    <div class="row justify-content-between">
        <div class="col-md-6">
            <div class="d-grid gap-3 d-md-block">
                <router-link to="/master-vendor/create" class="btn btn-primary btn-sm me-md-1">
                    <i class="bi bi-file-earmark"></i> Create
                </router-link>

            </div>
        </div>
        <div class="col-md-6">
            <div class="pagination-right">
                <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow" @toPage="toPage" />
            </div>
        </div>
    </div>

    <div class="">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th rowspan="2">
                        Action
                    </th>
                    <th>
                        <bs-ordering property="code" @reorder="reorder">Code</bs-ordering>
                    </th>
                    <th>
                        <bs-ordering property="name" @reorder="reorder">Name</bs-ordering>
                    </th>
                    
                </tr>
                <tr>
                    <th>
                        <div>
                            <bs-textbox placeholder="Code" v-model="filter.codeContains" @keyup="search()" />
                        </div>
                    </th>
                    <th>
                        <div>
                            <bs-textbox placeholder="Name" v-model="filter.nameContains" @keyup="search()" />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="data != null && data.length > 0">
                    <tr v-for="item in data" :key="item.id">
                        <td>
                            <div class="btn-group">
                                <button class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-menu-down"></i> Action
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <button @click="detailItem(item)" class="dropdown-item"><i class="bi bi-eye"></i> View Details</button>
                                    </li>
                                    <li>
                                        <button @click="editItem(item)" class="dropdown-item"><i class="bi bi-pencil-square"></i> Edit</button>
                                    </li>
                                    <!--<li>
                                        <button @click="deleteItem(item)" class="dropdown-item"><i class="bi bi-trash"></i> Delete</button>
                                    </li>-->
                                </ul>
                            </div>
                        </td>
                        <td>
                            {{item.code}}
                        </td>

                        <td>
                            {{item.name}}
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import MasterVendorService from "@/services/master-vendor-service.js";
export default {
    data: function () {
        return {
            filter: {
                codeContains: '',
                page: 1,
                perPage: 10,
                orderByProperty: 'id',
                orderType: 0
            },
            errorMessages: null,
            pageNumber: 1,
            perPage: 10,
            totalPage: 100,
            totalRow: 0,
            data: Array()
        }
    },
    watch: {
        $route: function() {
            if (this.$route.path == "/master-vendor") {
                this.loadData();
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async toPage(pageNumber) {
            this.filter.page = pageNumber;
            this.loadData();
        },
        async reorder(property, orderType) {
            this.filter.orderByProperty = property;
            this.filter.orderType = orderType;
            this.loadData();
        },
        async search() {
            this.filter.page = 1;
            this.loadData();
        },
        async loadData() {
            const result = await MasterVendorService.getList(this.filter);
            if (result.status) {
                this.errorMessages = null; 
            }
            else {
                this.errorMessages = result.errorMessages;
            }

            const data = result.data;
            this.pageNumber = data.pageNumber;
            this.perPage = data.perPage;
            this.totalPage = data.totalPage;
            this.totalRow = data.totalRow;
            this.data = data.data;
        },
        detailItem(item) {
            this.$router.push(`/master-vendor/detail/${item.id}`);
        },
        editItem(item) {
            this.$router.push(`/master-vendor/edit/${item.id}`);
        }
        //,
        //async deleteItem(item) {
        //    if (confirm(`Are you sure want to delete vendor code ${item.code} with name ${item.name}`)) {
        //        const result = await MasterVendorService.delete(item.id);
        //        if (result.status) {
        //            this.loadData();
        //        }
        //    }
        //}
    }
}
</script>

<style>
    
</style>